var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "app-sidebar-content" }, [
        _c(
          "div",
          { staticClass: "sidebar-my-menu-edit-layer" },
          [
            _c("div", { staticClass: "sidebar-menu-all-title" }, [
              _vm._v(_vm._s(_vm.$language("편집 후 저장을 클릭하세요."))),
            ]),
            _vm.bookmarks.length == 0
              ? _c("div", { staticClass: "no-bookmark" }, [
                  _vm._v(_vm._s(_vm.$language("나만의 메뉴가 없습니다."))),
                ])
              : _c(
                  "draggable",
                  _vm._b(
                    {
                      staticClass: "sidebar-my-ol",
                      attrs: {
                        tag: "ul",
                        list: _vm.bookmarks,
                        move: _vm.checkMove,
                      },
                      on: {
                        start: function ($event) {
                          _vm.drag = true
                        },
                        end: function ($event) {
                          _vm.drag = false
                        },
                        change: _vm.moveMenu,
                      },
                    },
                    "draggable",
                    _vm.dragOptions,
                    false
                  ),
                  _vm._l(_vm.bookmarks, function (item, index) {
                    return _c(
                      "li",
                      { key: index, staticClass: "sidebar-my-menu-item" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "shortcut_a",
                            on: {
                              click: function ($event) {
                                return _vm.goMobilePage(item.href)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "sa_mw" }, [
                              _c(
                                "i",
                                {
                                  class: [
                                    "material-icons sidebar-my-menu-icon",
                                    _vm.getIcons(item.icon, "color"),
                                  ],
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.getIcons(item.icon, "icon"))
                                  ),
                                ]
                              ),
                              _c("div", {
                                staticClass: "sidebar-my-menu-title",
                                domProps: {
                                  innerHTML: _vm._s(
                                    item.title.replace(" ", "<br/>")
                                  ),
                                },
                              }),
                              _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons sidebar-my-menu-icon-edit",
                                },
                                [_vm._v("bookmark")]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "save-bottom-box-btn" },
        [
          _c(
            "q-btn-group",
            { attrs: { outline: "" } },
            [
              _c("c-btn", {
                attrs: { label: "저장", icon: "save" },
                on: { btnClicked: _vm.saveSetting },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }